import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import cn from "classnames";
import { useFlexSearch } from "react-use-flexsearch";
import { useSearchStore } from "~store";
import { useWindowDimensions } from "~hooks";
import { PageHeading } from "~slices";
import { Button, Grid, GlitchImage, Image, Link } from "~components";

import { ReactComponent as XCircle } from "~assets/svg/x-circle.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * 404
 */
const SearchPage = ({ data: staticData, location }) => {
  // ---------------------------------------------------------------------------
  // hooks / store

  const { searchTerm, setSearchTerm } = useSearchStore();
  const { isDesktop } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [hovering, setHovering] = useState(false);
  const [results, setResults] = useState([]);

  // ---------------------------------------------------------------------------
  // variables

  const { localSearchPages, localSearchEvents } = staticData;

  const pageResults = useFlexSearch(
    searchTerm,
    localSearchPages?.index,
    localSearchPages?.store
  );

  const eventResults = useFlexSearch(
    searchTerm,
    localSearchEvents?.index,
    localSearchEvents?.store
  );

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setResults([
      ...eventResults.sort(
        (a, b) => new Date(a.sortableDate) - new Date(b.sortableDate)
      ),
      ...pageResults
    ]);
  }, [eventResults, pageResults]);

  // ---------------------------------------------------------------------------
  // render

  const maxContentLength = isDesktop ? 300 : 100;

  return (
    <article className={styles.container}>
      <PageHeading
        data={{
          backgroundColor: {
            hex: `#000000`
          },
          fontColor: {
            hex: `#ffffff`
          },
          heading: `SEARCH`
        }}
      />

      <section className={styles.container}>
        <Grid>
          <div className={styles.searchControls}>
            <input
              className={styles.searchInput}
              placeholder="Enter your search..."
              onChange={(e) => setSearchTerm(e.currentTarget.value)}
              type="text"
              value={searchTerm}
            />

            <button
              type="button"
              className={styles.searchClear}
              onClick={() => {
                setSearchTerm(``);
              }}
            >
              <XCircle className={styles.searchClearIcon} />

              <span className="button-text">CLEAR</span>
            </button>
          </div>
        </Grid>

        <Grid>
          <div className={styles.searchResults}>
            <h3 className={cn(styles.searchResultsHeading, `button-text`)}>
              RESULTS
            </h3>

            <br />

            {(!results?.[0] && (
              <>
                <h3 className="h3">No results found.</h3>
              </>
            )) ||
              results.map((result, resultIndex) => {
                const isEvent = result?.artist;
                const url = `${isEvent ? `/events/` : `/`}${result.slug}`;

                return (
                  <article
                    key={`search-result-${result?.title}`}
                    className={styles.searchResult}
                    onMouseEnter={() => setHovering(resultIndex)}
                    onMouseLeave={() => setHovering(null)}
                  >
                    <Link to={url}>
                      <div className={styles.searchResultContentFlex}>
                        <div className={styles.searchResultContent}>
                          <header>
                            <h3 className={cn(`caption`)}>
                              {result?.artist
                                ? `EVENT | ${result.datesShort}`
                                : `PAGE`}
                            </h3>

                            <p className={cn(styles.searchResultTitle, `h3`)}>
                              {result.title}
                            </p>

                            {isEvent && result?.tags?.[0] && (
                              <div className={styles.searchResultTags}>
                                {result?.tags.map((tag) => (
                                  <Button
                                    key={`button-tag-${tag.title}`}
                                    className={styles.searchResultTag}
                                    color="green"
                                    transparent
                                  >
                                    <span className="caption">{tag.title}</span>
                                  </Button>
                                ))}
                              </div>
                            )}
                          </header>

                          <div>
                            {result?.body?.[0]?._rawChildren?.[0]?.text && (
                              <p className={cn(styles.searchResultBody, `b1`)}>
                                {result.body[0]._rawChildren[0].text.length >
                                maxContentLength
                                  ? `${result.body[0]._rawChildren[0].text.substr(
                                      0,
                                      maxContentLength
                                    )} ...`
                                  : result.body[0]._rawChildren[0].text}
                              </p>
                            )}
                          </div>
                        </div>

                        {isEvent && (
                          <figure className={styles.searchResultImage}>
                            {/* <GlitchImage
                            active={hovering === resultIndex}
                            image={result.cardImage}
                          /> */}
                            <Image
                              className={styles.image}
                              image={result.cardImage}
                            />
                          </figure>
                        )}
                      </div>
                    </Link>
                  </article>
                );
              })}
          </div>
        </Grid>
      </section>
    </article>
  );
};

export default SearchPage;

export const query = graphql`
  query Search {
    localSearchPages {
      index
      store
    }
    allSanityPage {
      edges {
        node {
          slug {
            current
          }

          title

          pagebuilder {
            slices {
              ... on SanityPageHeading {
                _type
                heading
              }

              ... on SanityExpandableTextArticle {
                _type
                title

                body {
                  _rawChildren
                }
              }

              ... on SanityTextArticle {
                _type
                title

                body {
                  _rawChildren
                }
              }
            }
          }
        }
      }
    }

    #

    localSearchEvents {
      index
      store
    }
    allSanityEvent {
      edges {
        node {
          slug {
            current
          }

          title
          artist
          venue
          body {
            _rawChildren
          }
        }
      }
    }
  }
`;
