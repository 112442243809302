// extracted by mini-css-extract-plugin
export var container = "styles-module--container--593c4";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--c0cc5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var searchClear = "styles-module--searchClear--cbde3";
export var searchClearIcon = "styles-module--searchClearIcon--c779d";
export var searchControls = "styles-module--searchControls--51829";
export var searchControlsHeading = "styles-module--searchControlsHeading--4aa33";
export var searchIcon = "styles-module--searchIcon--da6aa";
export var searchInput = "styles-module--searchInput--81d80";
export var searchResult = "styles-module--searchResult--b60b6";
export var searchResultBody = "styles-module--searchResultBody--7634a";
export var searchResultContent = "styles-module--searchResultContent--e1b08";
export var searchResultContentFlex = "styles-module--searchResultContentFlex--e4bbf";
export var searchResultImage = "styles-module--searchResultImage--b5ec6";
export var searchResultTag = "styles-module--searchResultTag--bb98f";
export var searchResultTags = "styles-module--searchResultTags--75d66";
export var searchResultTitle = "styles-module--searchResultTitle--37cd7";
export var searchResults = "styles-module--searchResults--d4277";
export var searchResultsHeading = "styles-module--searchResultsHeading--9cac0";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";